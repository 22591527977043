<template>
    <BT-Blade-Items
        addBladeName="supplier-consignment"
        bladeName="supplier-consignments"
        :bladesData="bladesData"
        :headers="[
            { text: 'CO#', value: 'consignmentNumber' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' },
            { text: 'Supplier', value: 'seller.companyName' },
            { text: 'Depart From', value: 'departureLocation', textFilter: 'toLocationLine' },
            { text: 'Deliver To', value: 'destinationLocation.locationName' }]"
        hideActions
        navigation="supplier-consignments"
        showList
        title="Deliveries"
        :useServerPagination="true">
        <template v-slot:list="{ items }">
            <v-card v-for="(item, fIndex) in items" :key="fIndex" class="mx-1 mb-1" :to="{ name: 'supplier-consignment', params: { id: item.id }}">
                <v-row no-gutters>
                    <v-col cols="6" md="2" class="d-flex justify-center align-center">
                        <v-avatar rounded>
                            <v-img :src="companyLogoURL(item.sellerID)">
                                <template v-slot:placeholder>
                                    <v-icon class="primary--text" size="48">mdi-account-outline</v-icon>
                                </template>
                            </v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="6" md="4" class="d-flex justify-center align-center">
                        <v-list-item three-line>
                            <!-- <v-list-item-content v-if="item.isBuyerCourier && item.isReadyToOrderCourier && !item.isOrdered">
                                <v-list-item-title>CO# {{ item.consignmentNumber }}</v-list-item-title>
                                <v-btn>Organize Courier</v-btn>
                            </v-list-item-content> -->
                            <v-list-item-content>
                                <v-list-item-title>CO# {{ item.consignmentNumber }}</v-list-item-title>
                                <v-list-item-subtitle v-if="item.dueDate != null">Due {{ item.dueDate | toRelativeTime }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.dueDate != null">{{ item.dueDate | toDayShortDate }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-else>(No Due Date)</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col v-if="!item.isRequestPickup" cols="6" md="3" class="d-flex justify-center align-center">
                        <BT-Field-Address
                            label="DELIVER TO"
                            v-model="item.destinationLocation" />
                    </v-col>
                    <v-col v-else cols="6" md="3" class="d-flex justify-center align-center">
                        <BT-Field-Address
                            label="PICKUP FROM"
                            v-model="item.departureLocation" />
                    </v-col>
                    <v-col cols="6" md="3" class="d-flex justify-center align-center flex-column">
                        <div v-if="item.isBuyerCourier">
                            <v-alert v-if="item.isOrdered" type="success" dense shaped class="ma-0 pa-1">
                                <span v-if="item.isRequestPickup">Pickup Organized</span>
                                <span v-else>Courier Requested</span>
                            </v-alert>
                            <v-alert v-else-if="item.isReadyToOrderCourier" type="warning" dense shaped class="ma-0 pa-1">
                                <span v-if="item.isRequestPickup">Ready For Pickup</span>
                                <span v-else>Ready To Courier</span>
                            </v-alert>
                            <v-alert v-else type="error" dense shaped class="ma-0 pa-1">
                                Not Ready Yet
                            </v-alert>
                            <BT-Btn
                                v-if="item.isBuyerCourier && !item.isOrdered && item.isReadyToOrderCourier"
                                class="mt-2"
                                inline
                                label="Organize Courier"
                                rightIcon="mdi-arrow-right"
                                small
                                text />
                        </div>
                        <div v-else>
                            <v-alert v-if="item.isOrdered" type="success" dense shaped class="ma-0 pa-1">
                                <span v-if="item.isRequestPickup">Pickup Organized</span>
                                <span v-else>Courier Requested</span>
                            </v-alert>
                            <v-alert v-else-if="item.isReadyToOrderCourier" type="warning" dense shaped class="ma-0 pa-1">
                                <span v-if="item.isRequestPickup">Ready For Pickup</span>
                                <span v-else>Ready To Courier</span>
                            </v-alert>
                            <v-alert v-else type="error" dense shaped class="ma-0 pa-1">
                                Not Ready Yet
                            </v-alert>
                        </div>
                        
                        
                        <!-- <div>Ready</div>
                        <div>Not Ready Yet</div> -->
                    </v-col>
                    <!-- <v-col v-if="item.isBuyerCourier && item.isReadyToOrderCourier && !item.isOrdered" 
                        cols="6"
                        class="d-none d-md-block text-center">
                        <span>*Ready For You To Organize Courier</span>
                    </v-col> -->
                </v-row>
            </v-card>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Supplier-Consignments',
    props: {
        bladesData: null
    }
}
</script>